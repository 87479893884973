import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import {
  chain,
  filter,
  first,
  groupBy,
  indexOf,
  isEmpty,
  isUndefined,
  keys,
  map,
  omit,
  orderBy,
} from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "react-use";
import useCreateSubmission from "../hooks/useCreateSubmission";
import useGetForm from "../hooks/useGetForm";
import { TSubmissionAPI } from "../types/api";
import CompanyInfo from "./CompanyInfo";
import Operation from "./Operation";
import ScoreDrawer from "./ScoreDrawer";

export const SubmissionForm = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const getForm = useGetForm(Number(id));
  const mutation = useCreateSubmission();
  const orderedData = useMemo(
    () =>
      orderBy(
        getForm.data?.data.attributes.questions.data,
        "attributes.group.data.attributes.order",
        ["asc", "desc"]
      ),
    [getForm.data?.data.attributes.questions.data]
  );
  const categories = useMemo(
    () => groupBy(orderedData, "attributes.group.data.attributes.category"),
    [orderedData]
  );
  const categoriesList = ["company", ...keys(categories)];
  const [currentCategory, setCurrentCategory] = useState<string | string[]>(
    "company"
  );
  const [cache, setCache, remove] = useLocalStorage<
    TSubmissionAPI.CreatePayload | undefined
  >("cache");
  const navigate = useNavigate();

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setCurrentCategory(
          categoriesList?.[indexOf(categoriesList, currentCategory) + 1]
        );
        if (!isEmpty(values?.data?.answers?.data)) {
          values.data.answers.data = filter(
            values.data.answers.data,
            (ans) => !isEmpty(ans)
          );
          values.data.answers.data = map(values.data.answers.data, (ans) => ({
            ...ans,
            lastUpdateOPS:
              ans.lastUpdateOPS && dayjs(ans.lastUpdateOPS, "YYYY-MM-DD"),
          }));
        }
        setCache({ data: { ...cache?.data, ...values?.data } });
      })
      .catch(() => {
        message.error("يرجى ملء جميع البيانات");
      });
  };

  const handlePrev = () => {
    setCurrentCategory(
      categoriesList?.[indexOf(categoriesList, currentCategory) - 1]
    );
  };

  const onFinish = (values: TSubmissionAPI.CreatePayload) => {
    mutation.mutate(values, {
      onSuccess: () => {
        remove();
        navigate("/success");
      },
    });
  };

  useEffect(() => {
    if (isUndefined(cache)) {
      form.resetFields();
      setCurrentCategory("company");
    }
  }, [cache, form]);

  useEffect(() => {
    document.title = getForm.data?.data.attributes.name || "Dash Point";
  }, [getForm.data?.data.attributes.name]);

  return (
    <>
      <Typography.Title level={2} className="text-center">
        {getForm.data?.data.attributes.name}
      </Typography.Title>
      <Row justify="center">
        <Form
          autoComplete="off"
          colon={false}
          layout="vertical"
          form={form}
          name="prize-form"
          className="w-full"
          onFinish={onFinish}
          requiredMark
          initialValues={omit(cache, "data.answers")}
          onError={() => message.error("يرجى ملء جميع البيانات")}
        >
          <Form.Item
            className="hidden"
            hidden
            name={["data", "form"]}
            initialValue={id}
            noStyle
          >
            <Input />
          </Form.Item>
          <ScoreDrawer />
          <Popconfirm
            title="هل أنت متأكد أنك تريد اتخاذ هذا الإجراء؟"
            okText="نعم"
            cancelText="لا"
            onConfirm={remove}
          >
            <Button type="link" icon={<ReloadOutlined />}>
              محو البيانات
            </Button>
          </Popconfirm>
          <Collapse
            accordion
            activeKey={currentCategory}
            expandIcon={(props) =>
              props.isActive ? <MinusOutlined /> : <PlusOutlined />
            }
          >
            <Collapse.Panel
              style={{ overflow: "auto" }}
              key="company"
              header={
                <Typography.Title level={3} className="w-full">
                  المنظمة
                </Typography.Title>
              }
            >
              <CompanyInfo />
            </Collapse.Panel>
            {map(categories, (questions, category) => (
              <Collapse.Panel
                style={{ overflow: "auto" }}
                key={category}
                header={
                  <Typography.Title
                    level={3}
                    className="w-full"
                    style={{
                      color:
                        first(questions)?.attributes?.group?.data.attributes
                          .color,
                    }}
                  >
                    {category}
                  </Typography.Title>
                }
              >
                {chain(questions)
                  .groupBy("attributes.group.data.attributes.name")
                  .map((questions, groupName) => (
                    <Operation
                      key={groupName}
                      questions={questions}
                      groupName={
                        getForm.data?.data.attributes.showGroup
                          ? groupName
                          : undefined
                      }
                      cache={cache}
                      hasResult={getForm.data?.data?.attributes?.hasResult}
                    />
                  ))
                  .value()}
              </Collapse.Panel>
            ))}
          </Collapse>

          <Form.Item>
            <Space className="w-full justify-center mt-4">
              <Button
                className="text-xl"
                size="large"
                icon={<ArrowDownOutlined />}
                onClick={handleNext}
              >
                التالي
              </Button>
              <Button
                className="text-xl"
                size="large"
                icon={<ArrowUpOutlined />}
                onClick={handlePrev}
                disabled={currentCategory === "company"}
              >
                السابق
              </Button>
              <Button
                htmlType="submit"
                className="text-xl"
                size="large"
                icon={<SaveOutlined />}
                disabled={!isUndefined(currentCategory)}
                loading={mutation.isLoading}
              >
                إرسال
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Row>
    </>
  );
};
