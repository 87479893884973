import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { find, first, map, orderBy } from "lodash";
import { memo } from "react";
import { scoreColor } from "../constants";
import { TSubmissionAPI } from "../types/api";
import { Question } from "../types/models";

const Operation: React.FC<{
  groupName?: string;
  questions: Question[];
  cache?: TSubmissionAPI.CreatePayload;
  hasResult?: boolean;
}> = ({ groupName, questions, cache, hasResult }) => {
  const orderedData = orderBy(questions, "attributes.order", ["asc", "desc"]);
  const color = first(questions)?.attributes?.group?.data.attributes.color;
  return (
    <div key={groupName}>
      {groupName && (
        <Typography.Title level={4} style={{ margin: 0, color }}>
          {groupName}
        </Typography.Title>
      )}
      {map(orderedData, (question) => {
        const current = find(
          cache?.data?.answers?.data,
          (ans) => ans.question === Number(question.id)
        );
        return (
          <Space
            align="start"
            direction="vertical"
            key={question.id}
            className="w-full"
          >
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  <>
                    <Form.Item
                      noStyle
                      hidden
                      className="hidden"
                      name={[
                        "data",
                        "answers",
                        "data",
                        question.id,
                        "question",
                      ]}
                      initialValue={question.id}
                    >
                      <Input className="hidden" />
                    </Form.Item>

                    <Space direction="horizontal" size="large">
                      <Col>
                        <Space className="mb-4 flex items-center">
                          {question.attributes.description && (
                            <Tooltip
                              overlayInnerStyle={{ minWidth: "28rem" }}
                              color={
                                question?.attributes?.group?.data.attributes
                                  .color
                              }
                              placement="bottomLeft"
                              title={
                                <span className="text-lg">
                                  {question.attributes.description}{" "}
                                </span>
                              }
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          )}
                          <Typography.Title
                            style={{
                              margin: 0,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            level={5}
                          >
                            {question.attributes.title}
                          </Typography.Title>
                          <Space>
                            <Form.Item
                              noStyle
                              name={[
                                "data",
                                "answers",
                                "data",
                                question.id,
                                "operation",
                              ]}
                              initialValue={current?.operation}
                            >
                              <Input placeholder="مسمى العملية" />
                            </Form.Item>

                            {hasResult === true && (
                              <Form.Item
                                noStyle
                                rules={[{ required: false, message: "" }]}
                                name={[
                                  "data",
                                  "answers",
                                  "data",
                                  question.id,
                                  "lastUpdateOPS",
                                ]}
                                initialValue={
                                  current?.lastUpdateOPS &&
                                  dayjs(current.lastUpdateOPS, "YYYY-MM-DD")
                                }
                              >
                                <DatePicker
                                  picker="year"
                                  placeholder="اختر تحديث"
                                  disabledDate={(current) =>
                                    current && current.valueOf() >= Date.now()
                                  }
                                />
                              </Form.Item>
                            )}
                          </Space>
                        </Space>
                        <Space>
                          <Col>
                            <Form.Item
                              tooltip="الي أي مدي تم تحديد المستهدفات  بشكل دقيق إعتمادا علي توقعات المعنيين وبحيث يمكن قياسه بشكل دوري؟"
                              rules={[{ required: false, message: "" }]}
                              label="تحديد الهدف"
                              className="w-32 cursor-pointer"
                              name={[
                                "data",
                                "answers",
                                "data",
                                question.id,
                                "planOPS",
                              ]}
                              initialValue={current?.planOPS}
                            >
                              <Select>
                                <Select.Option key="2" value="متواضع">
                                  <Tag color="red" className="text-base">
                                    متواضع
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="3" value="جيد">
                                  <Tag color="yellow" className="text-base">
                                    جيد
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="4" value="جيد جداً">
                                  <Tag color="green" className="text-base">
                                    جيد جداً
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="5" value="متميز">
                                  <Tag color="blue" className="text-base">
                                    متميز
                                  </Tag>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div
                              className={`w-32 h-6 rounded-lg`}
                              style={{
                                backgroundColor:
                                  scoreColor[
                                    getFieldValue([
                                      "data",
                                      "answers",
                                      "data",
                                      question.id,
                                      "planOPS",
                                    ])
                                  ] || "lightgray",
                              }}
                            />
                          </Col>
                          <Col>
                            <Form.Item
                              tooltip="الي أي مدي تم تصميم العملية آخذا في الإعتبار إرتباطها بالإستراتيجية وظروف العمل  وإعتمادا علي أراء المعنيين؟"
                              rules={[{ required: false, message: "" }]}
                              label="تصميم العملية"
                              className="w-32 cursor-pointer"
                              name={[
                                "data",
                                "answers",
                                "data",
                                question.id,
                                "applicationOPS",
                              ]}
                              initialValue={current?.applicationOPS}
                            >
                              <Select>
                                <Select.Option key="2" value="متواضع">
                                  <Tag color="red" className="text-base">
                                    متواضع
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="3" value="جيد">
                                  <Tag color="yellow" className="text-base">
                                    جيد
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="4" value="جيد جداً">
                                  <Tag color="green" className="text-base">
                                    جيد جداً
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="5" value="متميز">
                                  <Tag color="blue" className="text-base">
                                    متميز
                                  </Tag>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div
                              className={`w-32 h-6 rounded-lg`}
                              style={{
                                backgroundColor:
                                  scoreColor[
                                    getFieldValue([
                                      "data",
                                      "answers",
                                      "data",
                                      question.id,
                                      "applicationOPS",
                                    ])
                                  ] || "lightgray",
                              }}
                            />
                          </Col>

                          <Col>
                            <Form.Item
                              tooltip="الي أي مدي يتم تنفيذ العملية بشكل دوري أو عند الإحتياج وفي كافة المجالات والوحدات  والأقسام؟"
                              rules={[{ required: false, message: "" }]}
                              label="تنفيذ العملية"
                              className="w-32 cursor-pointer"
                              name={[
                                "data",
                                "answers",
                                "data",
                                question.id,
                                "MeasureOPS",
                              ]}
                              initialValue={current?.MeasureOPS}
                            >
                              <Select>
                                <Select.Option key="2" value="متواضع">
                                  <Tag color="red" className="text-base">
                                    متواضع
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="3" value="جيد">
                                  <Tag color="yellow" className="text-base">
                                    جيد
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="4" value="جيد جداً">
                                  <Tag color="green" className="text-base">
                                    جيد جداً
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="5" value="متميز">
                                  <Tag color="blue" className="text-base">
                                    متميز
                                  </Tag>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div
                              className={`w-32 h-6 rounded-lg`}
                              style={{
                                backgroundColor:
                                  scoreColor[
                                    getFieldValue([
                                      "data",
                                      "answers",
                                      "data",
                                      question.id,
                                      "MeasureOPS",
                                    ])
                                  ] || "lightgray",
                              }}
                            />
                          </Col>

                          <Col>
                            <Form.Item
                              tooltip="الي أي مدي يتم قياس تحقيق الأهداف المخططة والإستخدام الأمثل للموارد المخططة بشكل دوري؟"
                              rules={[{ required: false, message: "" }]}
                              label="قياس الاداء"
                              className="w-32 cursor-pointer"
                              name={[
                                "data",
                                "answers",
                                "data",
                                question.id,
                                "improveOPS",
                              ]}
                              initialValue={current?.improveOPS}
                            >
                              <Select>
                                <Select.Option key="2" value="متواضع">
                                  <Tag color="red" className="text-base">
                                    متواضع
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="3" value="جيد">
                                  <Tag color="yellow" className="text-base">
                                    جيد
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="4" value="جيد جداً">
                                  <Tag color="green" className="text-base">
                                    جيد جداً
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="5" value="متميز">
                                  <Tag color="blue" className="text-base">
                                    متميز
                                  </Tag>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div
                              className={`w-32 h-6 rounded-lg`}
                              style={{
                                backgroundColor:
                                  scoreColor[
                                    getFieldValue([
                                      "data",
                                      "answers",
                                      "data",
                                      question.id,
                                      "improveOPS",
                                    ])
                                  ] || "lightgray",
                              }}
                            />
                          </Col>

                          <Col>
                            <Form.Item
                              tooltip="الي أي مدي يتم الإعتماد علي الأفكار الإبداعية والإبتكار في تنفيذ التحسينات علي العملية و أسلوب اتطبيقها وتحديد أولويات التنفيذ؟"
                              rules={[{ required: false, message: "" }]}
                              label="إبتكار التحسينات"
                              className="w-32 cursor-pointer"
                              name={[
                                "data",
                                "answers",
                                "data",
                                question.id,
                                "creativeOPS",
                              ]}
                              initialValue={current?.creativeOPS}
                            >
                              <Select>
                                <Select.Option key="2" value="متواضع">
                                  <Tag color="red" className="text-base">
                                    متواضع
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="3" value="جيد">
                                  <Tag color="yellow" className="text-base">
                                    جيد
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="4" value="جيد جداً">
                                  <Tag color="green" className="text-base">
                                    جيد جداً
                                  </Tag>
                                </Select.Option>
                                <Select.Option key="5" value="متميز">
                                  <Tag color="blue" className="text-base">
                                    متميز
                                  </Tag>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <div
                              className={`w-32 h-6 rounded-lg`}
                              style={{
                                backgroundColor:
                                  scoreColor[
                                    getFieldValue([
                                      "data",
                                      "answers",
                                      "data",
                                      question.id,
                                      "creativeOPS",
                                    ])
                                  ] || "lightgray",
                              }}
                            />
                          </Col>
                        </Space>
                      </Col>
                      {hasResult && (
                        <Col>
                          <Typography.Title
                            level={5}
                            style={{
                              marginBottom: "1.5rem",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {" "}
                            نتائج {question.attributes.title}
                          </Typography.Title>

                          <Space>
                            <Col>
                              <Form.Item
                                tooltip="الي أي مدي إشتملت النتائج المعروضة علي كافة النتائج الهامة ذات الصلة والمتعلقة بتحقيق الأهداف الإستراتيجية؟
الي أي مدي تم تقديم مجموعة من النتائج، تغطي ثلاث فترات متتالية على الأقل لنشر الممارسات ، بما يتماشى مع التخطيط ودورات مراجعة الأداء"
                                rules={[{ required: false, message: "" }]}
                                label="الاتجاهات"
                                className="w-32 cursor-pointer"
                                name={[
                                  "data",
                                  "answers",
                                  "data",
                                  question.id,
                                  "directionRES",
                                ]}
                                initialValue={current?.directionRES}
                              >
                                <Select>
                                  <Select.Option key="2" value="متواضع">
                                    <Tag color="red" className="text-base">
                                      متواضع
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="3" value="جيد">
                                    <Tag color="yellow" className="text-base">
                                      جيد
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="4" value="جيد جداً">
                                    <Tag color="green" className="text-base">
                                      جيد جداً
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="5" value="متميز">
                                    <Tag color="blue" className="text-base">
                                      متميز
                                    </Tag>
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <div
                                className={`w-32 h-6 rounded-lg`}
                                style={{
                                  backgroundColor:
                                    scoreColor[
                                      getFieldValue([
                                        "data",
                                        "answers",
                                        "data",
                                        question.id,
                                        "directionRES",
                                      ])
                                    ] || "lightgray",
                                }}
                              />
                            </Col>

                            <Col>
                              <Form.Item
                                tooltip="الي أي مدي تم وضع مستهدفاتات وقياسها لمدة 3 سنوات والي أي مدي تم تحقيق المستهدفات التي تم تحديدها؟"
                                rules={[{ required: false, message: "" }]}
                                label="المستهدفات"
                                className="w-32 cursor-pointer"
                                name={[
                                  "data",
                                  "answers",
                                  "data",
                                  question.id,
                                  "targetRES",
                                ]}
                                initialValue={current?.targetRES}
                              >
                                <Select>
                                  <Select.Option key="2" value="متواضع">
                                    <Tag color="red" className="text-base">
                                      متواضع
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="3" value="جيد">
                                    <Tag color="yellow" className="text-base">
                                      جيد
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="4" value="جيد جداً">
                                    <Tag color="green" className="text-base">
                                      جيد جداً
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="5" value="متميز">
                                    <Tag color="blue" className="text-base">
                                      متميز
                                    </Tag>
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <div
                                className={`w-32 h-6 rounded-lg`}
                                style={{
                                  backgroundColor:
                                    scoreColor[
                                      getFieldValue([
                                        "data",
                                        "answers",
                                        "data",
                                        question.id,
                                        "targetRES",
                                      ])
                                    ] || "lightgray",
                                }}
                              />
                            </Col>

                            <Col>
                              <Form.Item
                                tooltip="الي أي مدي تم مقارنة النتائج مع نتائج افضل منافس؟ والي أي مدي النتائج تفوق نتائج أفضل الممارسات ؟"
                                rules={[{ required: false, message: "" }]}
                                label="المقارنات"
                                className="w-32 cursor-pointer"
                                name={[
                                  "data",
                                  "answers",
                                  "data",
                                  question.id,
                                  "comparisonRES",
                                ]}
                                initialValue={current?.comparisonRES}
                              >
                                <Select>
                                  <Select.Option key="2" value="متواضع">
                                    <Tag color="red" className="text-base">
                                      متواضع
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="3" value="جيد">
                                    <Tag color="yellow" className="text-base">
                                      جيد
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="4" value="جيد جداً">
                                    <Tag color="green" className="text-base">
                                      جيد جداً
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="5" value="متميز">
                                    <Tag color="blue" className="text-base">
                                      متميز
                                    </Tag>
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <div
                                className={`w-32 h-6 rounded-lg`}
                                style={{
                                  backgroundColor:
                                    scoreColor[
                                      getFieldValue([
                                        "data",
                                        "answers",
                                        "data",
                                        question.id,
                                        "comparisonRES",
                                      ])
                                    ] || "lightgray",
                                }}
                              />
                            </Col>

                            <Col>
                              <Form.Item
                                tooltip="الي أي مدي هناك ربط واضح بين العملية المستخدمة والنتائج المحققة؟"
                                rules={[{ required: false, message: "" }]}
                                label="الارتباط"
                                className="w-32 cursor-pointer"
                                name={[
                                  "data",
                                  "answers",
                                  "data",
                                  question.id,
                                  "relationRES",
                                ]}
                                initialValue={current?.relationRES}
                              >
                                <Select>
                                  <Select.Option key="2" value="متواضع">
                                    <Tag color="red" className="text-base">
                                      متواضع
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="3" value="جيد">
                                    <Tag color="yellow" className="text-base">
                                      جيد
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="4" value="جيد جداً">
                                    <Tag color="green" className="text-base">
                                      جيد جداً
                                    </Tag>
                                  </Select.Option>
                                  <Select.Option key="5" value="متميز">
                                    <Tag color="blue" className="text-base">
                                      متميز
                                    </Tag>
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                              <div
                                className={`w-32 h-6 rounded-lg`}
                                style={{
                                  backgroundColor:
                                    scoreColor[
                                      getFieldValue([
                                        "data",
                                        "answers",
                                        "data",
                                        question.id,
                                        "relationRES",
                                      ])
                                    ] || "lightgray",
                                }}
                              />
                            </Col>
                          </Space>
                        </Col>
                      )}
                    </Space>
                  </>
                );
              }}
            </Form.Item>
          </Space>
        );
      })}
    </div>
  );
};

export default memo(Operation);
