import { notification } from "antd";
import { useQuery } from "react-query";
import { Form } from "../api";

export default function useListForms() {
  return useQuery(["list-forms"], Form.list, {
    refetchOnWindowFocus: false,
    onError: () => notification.error({ message: "Something went wrong" }),
  });
}
