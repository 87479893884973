import { Select } from 'antd'
import { map } from 'lodash'
import { useNavigate } from 'react-router-dom'
import useListForms from '../hooks/useListForms'

const FormSelect = () => {
  const forms = useListForms()
  const navigate = useNavigate()
  const handleFormSelect = (id: string) => navigate(`form/${id}`)


  return (
    <Select className="w-72 " placeholder='حجم الشركة' size="large" onChange={handleFormSelect} loading={forms.isLoading}>
      {map(forms.data?.data, (form) => (
        <Select.Option key={form.id} value={form.id}>
          {form.attributes.name}
        </Select.Option>))}
    </Select>
  )
}

export default FormSelect