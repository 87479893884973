import { Result } from "antd";
import { Content } from "antd/es/layout/layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import logo from './assets/logo.jpg';
import FormSelect from "./components/FormSelect";
import { SubmissionForm } from "./components/SubmissionForm";

const queryClient = new QueryClient();

function App() {
  return (

    <QueryClientProvider client={queryClient}>
      <Content
        className="p-4"
        dir="rtl"
      >
        <div className="w-full flex flex-col items-center mb-10">
          <img src={logo} alt="Logo" className="w-36 md:w-72" />
          {/* <Typography.Title className="text-center" level={2} style={{ color: '#C9A550' }}>منظومة التقييم الرقمي</Typography.Title> */}
          {/* <Tag className="text-xl" color="gold">إطار التميز التشغيلي</Tag> */}
        </div>
        <Routes>
          <Route path="/" element={<FormSelect />} />
          <Route path="form/:id" element={<SubmissionForm />} />
          <Route path="success" element={<Result
            status="success"
            title='تم إرسال البيانات بنجاح'
            subTitle='سيتم التواصل معكم قريباً'
          />} />
        </Routes>
      </Content>
    </QueryClientProvider>
  );
}

export default App;
