import axios from "axios";
import { TFormAPI, TSubmissionAPI } from "../types/api";

export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;

export namespace Form {
  export async function list(): Promise<TFormAPI.ListResponse> {
    const response = await axios.get(
      `${API_URL}/forms?populate[questions][populate][0]=group`,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    return response.data;
  }

  export async function get(id: number): Promise<TFormAPI.GetResponse> {
    const response = await axios.get(
      `${API_URL}/forms/${id}?populate[questions][populate][0]=group&pagination[page]=1&pagination[pageSize]=300`,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    return response.data;
  }
}

export namespace Submission {
  export async function create(
    payload: TSubmissionAPI.CreatePayload
  ): Promise<{ count: number }> {
    const response = await axios.post(`${API_URL}/submissions`, payload, {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
      },
    });
    return response.data;
  }
}
