import { UnorderedListOutlined } from '@ant-design/icons'
import { Button, Descriptions, Drawer, Tag } from 'antd'
import { useState } from 'react'

const ScoreDrawer = () => {
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  return (
    <>
      <Button onClick={toggle} type='link' icon={<UnorderedListOutlined />}>دليل التقييم</Button>
      <Drawer title="دليل التقييم " placement="left" open={open} mask={false} width='250' onClose={() => setOpen(false)}>
        <Descriptions bordered size='small' >
          <Descriptions.Item span={3} label={
            <Tag color="red" className="text-base">
              متواضع
            </Tag>}>
            5-25
          </Descriptions.Item>
          <Descriptions.Item span={3} label={
            <Tag color="yellow" className="text-base">
              جيد
            </Tag>}>
            30-50
          </Descriptions.Item>
          <Descriptions.Item span={3} label={
            <Tag color="green" className="text-base">
              جيد جداً
            </Tag>}>
            55-75
          </Descriptions.Item>
          <Descriptions.Item span={3} label={
            <Tag color="blue" className="text-base">
              متميز
            </Tag>}>
            80-100
          </Descriptions.Item>
        </Descriptions>
      </Drawer >
    </>
  )
}

export default ScoreDrawer