import { Form, Input, InputNumber, Space } from 'antd'

const CompanyInfo = () => {
  return (
    <Space direction='vertical'>
      <Space>
        <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'company']}>
          <Input placeholder="أسم المؤسسة" />
        </Form.Item>
        <Form.Item rules={[{ required: false, message: '' }, {
          required: false,
          type: "email",
          message: "الإدخال ليس بريدًا إلكترونيًا صالحًا!",
        },]} name={['data', 'email']} >
          <Input placeholder="بريد إلكتروني" />
        </Form.Item>
      </Space>
      <Form.Item noStyle hidden>
        <Space>
          <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'city']} >
            <Input placeholder="المدينة" />
          </Form.Item>
          <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'country']} >
            <Input placeholder="البلد" />
          </Form.Item>
        </Space>
        <Space>
          <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'field']} >
            <Input placeholder="النشاط" />
          </Form.Item>
          <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'phone']} >
            <Input placeholder="موبايل" />
          </Form.Item>
        </Space>
        <Space>
          <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'manager']} >
            <Input placeholder="المدير المسئول" />
          </Form.Item>
          <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'count']} >
            <InputNumber placeholder="عدد العاملين" className='w-full' />
          </Form.Item>
        </Space>

        <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'contact']} >
          <Input placeholder="اسم مسئول التواصل" />
        </Form.Item>
        <Form.Item rules={[{ required: false, message: '' }]} name={['data', 'contactMobile']} >
          <Input placeholder="موبايل" />
        </Form.Item>
        <Form.Item
          rules={[{ required: false, message: '' }, {
            required: false,
            type: "email",
            message: "الإدخال ليس بريدًا إلكترونيًا صالحًا!",
          },]} name={['data', 'contactEmail']} >
          <Input placeholder="مسئول البريد الالكترونى" type='email' />
        </Form.Item>
      </Form.Item>
    </Space>

  )
}

export default CompanyInfo