import { notification } from "antd";
import { useMutation } from "react-query";
import { Submission } from "../api";

export default function useCreateSubmission() {
  return useMutation(["create-submission"], Submission.create, {
    onSuccess: () => notification.success({ message: "شكرا لتقريركم" }),
    onError: () => notification.error({ message: "عذرا، هناك خطأ ما" }),
  });
}
